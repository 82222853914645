import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_3/data.json';
import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';

import "./Seccion_3.css"

const Seccion_3 = (props) => {

    const style = {
        top: "0%",
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

      const styleImage2 = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 200%)",
        zIndex: "100"
      };

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* 

            {(props.scrollPosition > 2000) ? 
                <img id="fonddoSeccion3" src="./imgFondos/avion2.gif" style={styleImage2}></img>
            :
                ""
            }
               
            
            

            <object id="textoSeccion3" type="image/svg+xml" data="./img/Iglesia.svg"></object> */}

        </Fragment>
    );
}
 
export default Seccion_3