
import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_8/data.json';

import "./Seccion_8.css"

const Seccion_8 = (props) => {

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 699%)",
        zIndex: "-100",
      };

    const style = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* <img id="fonddoSeccion7" src="./imgFondos/7.jpg" style={styleImage}></img>


            <object id="textoSeccion7" type="image/svg+xml" data="./img/Itinerario.svg"></object> */}
        </Fragment>

    );
}
 
export default Seccion_8