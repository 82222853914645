
import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_9/data.json';
import Timer from '../Countdown/Timer';

import "./Seccion_9.css"

const Seccion_9 = (props) => {

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 699%)",
        zIndex: "-100",
      };

    const style = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

      const current = new Date();
      // it adds 15 days to a current date
      current.setDate(current.getDate()+17);
      //console.log(current);
      let ano = current.getUTCFullYear();
      let mes = current.getMonth() + 1;
      if(mes < 10) {
          mes = "0"+mes
      }
  
      let dia = current.getDate();
      console.log(current.getDate());
      if(dia < 10) {
          dia = "0"+dia
      }
      const fecha = ano+"-"+mes+"-"+dia+"T12:00:00.000Z";
      console.log(fecha);

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* <img id="fonddoSeccion7" src="./imgFondos/7.jpg" style={styleImage}></img>
            <object id="textoSeccion7" type="image/svg+xml" data="./img/Itinerario.svg"></object> */}

            <div id="textoSeccion9">
                  <img src="./img/Contador.svg" alt=""/>
                  <div id="contenedorTimer" >
                    <Timer deadline={"2023-07-01T00:00:00.000Z"} />
                    {/* <Timer deadline={fecha} /> */}
                  </div>
            </div>              
        </Fragment>

    );
}
 
export default Seccion_9
