import React, {useState, Fragment, useContext} from 'react';

import MensajeContexto from '../Mensaje/MensajeContexto';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_1/data.json';
import animationAvion from '../../data/Avion/data.json';

import "./Seccion_1.css"

const Seccion_1 = (props) => {

  const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    const styleImage2 = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "100"
      };

    const style = {
        top: "0%",
        margin: '-15px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    const styleAvion = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 0%)",
        zIndex: "300"
    };  

    const mensaje = () => {
      const timeout = setTimeout(() => {
        updateMostrarMensaje(true);
      }, 2000)      
    }    

    return (
        <Fragment>
           <Lottie 
                loop = "false"
                autoplay
                animationData={animationAvion}
                style={styleAvion}
                onComplete={mensaje}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            /> 
            
            {/* {mostrarMensaje ? <img id="fonddoSeccion1" src="./img/swipeIMG.svg" style={styleImage2}></img> : "" } */}
            {mostrarMensaje ? <div>
                                  <div class="scroll-up"></div>
                                  <div class="scroll-up2"></div>
                                  <div class="scroll-up3"></div>
                              </div>
                : "" } 
        </Fragment>
    );
}
 
export default Seccion_1