import React, {useRef, useState, useEffect, useContext, Fragment} from 'react';

import MensajeContexto from '../Mensaje/MensajeContexto';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_2/data.json';
import animationAvion from '../../data/Avion2/data.json';

import "./Seccion_2.css"

const Seccion_2 = (props) => {
    const lottieRef = useRef();
    const refSeccion2 = useRef(null);

    const [avionAnimacion, setAvionAnimacion]   = useState(false);

    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    useEffect(() => {
        const alturaIncio = window.innerHeight - 157;

        if( props.scrollPosition > alturaIncio ){
            setAvionAnimacion(true);
            lottieRef.current.play();
        } else {
            setAvionAnimacion(false);
            lottieRef.current.stop();
        }        
        
    }, [props.scrollPosition])

    const scrollCallback = (entries) => {
        if (entries[0].isIntersecting) {
            updateMostrarMensaje(false);
        }else{
            // do nothing
        }
    };

    useEffect(() => {
        // *** Grab the element related to this callback
        const { current } = refSeccion2;
        const observer = new IntersectionObserver(scrollCallback, {
            root: null,
            threshold: 1,
        });
        observer.observe(current);
        return () => {
            observer.disconnect(current); // *** Use the same element
        }
    }, [refSeccion2.current]); // *** Note dependency


    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 100%)",
        zIndex: "1000"
      };

    const style = {
        top: "0%",
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    const styleAvion = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 100%)",
        zIndex: "300"
    };  

    return (
        <Fragment>
            <div id="seccion2Box" ref={refSeccion2}></div>
            <Lottie 
                loop = "false"
                lottieRef={lottieRef}
                animationData={animationAvion}
                style={styleAvion}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            
            {/* <img id="fonddoSeccion2" src="./imgFondos/2.jpg" style={styleImage}></img> */}

        </Fragment>
    );
}
 
export default Seccion_2