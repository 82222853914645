import React, {useRef, useEffect, useState, Fragment} from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import "./Login.css"

// Json Lotties
import HorizontalScrollEcard from '../HorizontalScrollEcard/HorizontalScrollEcard'
import Seccion_1 from '../HorizontalScrollEcard/Seccion_1/Seccion_1'

import musica from './musica.mp3'

const  Login = (props) => {

    let {search} = useLocation();
    let query = new URLSearchParams(search);

    const navigate = useNavigate();
    const imgSwipeUpRef = useRef(null);
    const cajaTransparenteRef = useRef(null);

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( anchoAux )
        console.log( "altura: " + altura + "ancho: " + ancho  )
    }, [altura, ancho])

   // use Audio constructor to create HTMLAudioElement
    const audioTune = new Audio(musica);
    
    // variable to play audio in loop
    const [playInLoop, setPlayInLoop] = useState(true);
    
    // load audio file on component load
    useEffect(() => {
        audioTune.load();
    }, [])
    
    // set the loop of audio tune
    useEffect(() => {
        audioTune.loop = playInLoop;
    }, [playInLoop])
    
    // play audio sound
    const playSound = () => {
        imgSwipeUpRef.current.hidden = "true";
        cajaTransparenteRef.current.hidden = "true";
        audioTune.play();
        console.log("play");
        query.get("mkt") === null ? navigate(`/t`) :  navigate(`/t?mkt=${1}`);
    }
    
    // pause audio sound
    const pauseSound = () => {
        audioTune.pause();
        console.log("stop");
    }
    
    // stop audio sound
    const stopSound = () => {
        audioTune.pause();
        audioTune.currentTime = 0;
        console.log("pause");
    }

    const styleImage = {
        top: "0%",
        padding: 0,
        border: 0,
        height: "90vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "100"
      };
      
    return ( 
        <Fragment>
                <div id="uno" className="tarjeta" >
                    {/* <Seccion_1 altura={altura} ancho={ancho}/> */}
                    
                    <div class="transbox" onClick={playSound} ref={cajaTransparenteRef} >
                        <img id="swipeUp" src="./img/PantallaClick.svg" style={styleImage}  ref={ imgSwipeUpRef } alt=""></img>
                     </div>
                    
                </div>
        </Fragment>                  
    );

}
 
export default Login;