import React, {Fragment} from 'react';
import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_5/data.json';

// Estilo
import "./Seccion_5.css"

const Seccion_5 = (props) => {

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 400%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />

            {/* <img id="fonddoSeccion4" src="./imgFondos/4.jpg" style={styleImage}></img> */}

            <object id="textoSeccion5" type="image/svg+xml" data="./img/Fiesta.svg"></object>


        </Fragment>

    );
}
 
export default Seccion_5